$(document).ready(function () {
    $(".circle-text").lettering();
    // $("body").on("submit", ".js-form-submit", function (e) {
    //     e.preventDefault();
    //     var _form = $(this);
    //     if (!myValidateForm(_form)) {
    //         return false;
    //     }
    //
    // });
    // $("body").on("blur", ".js-form-submit input", function () {
    //     var _form = $(this).closest(".js-form-submit");
    //     if (!myValidateForm(_form)) {
    //         return false;
    //     }
    // });


});
$('.slick-slider2').on('init', function(event, slick, currentSlide, nextSlide){

    var $currentSlide = $(".slick-slider2").find("[data-slick-index=1]");

    var img = $currentSlide.find(".imagecontainer2 .item_img").attr("data-src");
    $(".slick-slider2").find(".slick-next2 .item_img").attr("data-src", img);

    var list = $(".slick-slider2").find(".slick-next2 .item_img");

    var src = list.attr("data-src");

    list.css('background-image', 'url("' + src + '")');

});

$('.slick-slider2').on('afterChange', function(event, slick, currentSlide){

    var cnt = slick.slideCount;
    var nextSlidendex = (currentSlide + 1) % cnt;
    var prevSlidindex = (currentSlide - 1);
    console.log(nextSlidendex);
    var $currentSlide = $(".slick-slider2").find("[data-slick-index="+nextSlidendex+"]");
    console.log($currentSlide);
    var img = $currentSlide.find(".imagecontainer2 .item_img").attr("data-src");

    var imgslick = $(".slick-slider2").find(".slick-next2 .item_img").attr("data-src", img);

    var list = $(".slick-slider2").find(".slick-next2 .item_img");

    var src = list.attr("data-src");

    imgslick.css('background-image', 'url("' + src + '")');


});
$('.slick-slider3').on('init', function(event, slick, currentSlide){
    var cnt = slick.slideCount;
    var nextSlidendex = (currentSlide + 1) % cnt;
    var prevSlidindex = (currentSlide - 1);
    console.log(nextSlidendex);
    var $currentSlide = $(".slick-slider3").find("[data-slick-index=1]");
    console.log($currentSlide);
    var img = $currentSlide.find(".imagecontainer .item_img").attr("data-src");

    var imgslick = $(".slick-slider3").find(".slick-next3 .item_img").attr("data-src", img);

    var list = $(".slick-slider3").find(".slick-next3 .item_img");

    var src = list.attr("data-src");

    imgslick.css('background-image', 'url("' + src + '")');


});
$('.slick-slider3').on('init', function(event, slick, currentSlide){
    var cnt = slick.slideCount;
    var nextSlidendex = (currentSlide + 1) % cnt;
    var prevSlidindex = (cnt - 1);

    var $currentSlide = $(".slick-slider3").find("[data-slick-index="+prevSlidindex+"]");

    var img = $currentSlide.find(".imagecontainer .item_img").attr("data-src");

    var imgslick = $(".slick-slider3").find(".slick-prev3 .item_img").attr("data-src", img);

    var list = $(".slick-slider3").find(".slick-prev3 .item_img");

    var src = list.attr("data-src");

    imgslick.css('background-image', 'url("' + src + '")');

});


$('.slick-slider3').on('afterChange', function(event, slick, currentSlide){
    var cnt = slick.slideCount;
    var nextSlidendex = (currentSlide + 1) % cnt;
    var prevSlidindex = (currentSlide - 1);
    console.log(nextSlidendex);
    var $currentSlide = $(".slick-slider3").find("[data-slick-index="+nextSlidendex+"]");
    console.log($currentSlide);
    var img = $currentSlide.find(".imagecontainer .item_img").attr("data-src");

    var imgslick = $(".slick-slider3").find(".slick-next3 .item_img").attr("data-src", img);

    var list = $(".slick-slider3").find(".slick-next3 .item_img");

    var src = list.attr("data-src");

    imgslick.css('background-image', 'url("' + src + '")');
});
$('.slick-slider3').on('afterChange', function(event, slick, currentSlide){
    var cnt = slick.slideCount;
    var nextSlidendex = (currentSlide + 1) % cnt;
    var prevSlidindex = (currentSlide - 1);
    console.log(nextSlidendex);
    var $currentSlide = $(".slick-slider3").find("[data-slick-index="+prevSlidindex+"]");
    console.log($currentSlide);
    var img = $currentSlide.find(".imagecontainer .item_img").attr("data-src");

    var imgslick = $(".slick-slider3").find(".slick-prev3 .item_img").attr("data-src", img);

    var list = $(".slick-slider3").find(".slick-prev3 .item_img");

    var src = list.attr("data-src");

    imgslick.css('background-image', 'url("' + src + '")');
});
$(document).ready(function () {
    $('.slick-slider').slick({
        "slidesToShow": 1,
        autoplay: false,
        arrows: true,
        nextArrow: '<div class="slick-next" ></div>',
        prevArrow: '<div class="slick-prev"></div>',
        dots: true,
        adaptiveHeight: true
    });

    $('.slick-slider2').slick({
        "slidesToShow": 1,
        autoplay: false,
        arrows: true,
        nextArrow: '<div class="slick-next2"><picture class="imagecontainer_slick"><div class="image_block"> <div  class="item_img"></div></div></picture></div>',
        dots: true,
        adaptiveHeight: true
    });




    $('.slick-slider3').slick({
        "slidesToShow": 1,
        autoplay: false,
        arrows: true,
        nextArrow: '<div class="slick-next3"><picture class="imagecontainer_slick"><div class="image_block"> <div  class="item_img"></div></div></picture></div>',
        prevArrow: '<div class="slick-prev3"><picture class="imagecontainer_slick"><div class="image_block"> <div  class="item_img"></div></div></picture></div>',
        dots: true
    });


    $('.list > li').click(function (event) {
        $(this).children(".answer").slideToggle(200);
         $(this).toggleClass('border');
        event.stopPropagation();
    });

    $('.js-show').click(function () {
        $('.block__hidden').slideToggle("300");
        $('.block_end').toggleClass('position_end');
        $('.block.-right').toggleClass('hide');
        event.stopPropagation();
    });
    /*$('.js-read-on').click(function () {
        $(this).addClass('close');
        $('.js-read-off').addClass('open');
        $('.slick-slider2 > .slick-list').addClass('open');
        event.stopPropagation();
    });
    $('.js-read-off').click(function () {
        $(this).removeClass('open');
        $('.js-read-on').removeClass('close');
        $('.slick-slider2 > .slick-list').removeClass('open');
        event.stopPropagation();
    });*/

    var $menu = $(".js-goto");
    var curTop = $(window).scrollTop();
    var prevPos = 0;
    var curBar = 0;
    $(window).scroll(function () {
        curTop = $(window).scrollTop();
        var i = 0;
        $menu.each(function(ind){
            var $this = $(this);
            var id = $this.attr("data-id");
            var top = $(id).offset().top - 300;
            if (prevPos > curTop && top  < curTop) {
                i = ind;
            } else if (prevPos < curTop && top  < curTop) {
                i = ind;
            }
        });
        i++;
        curBar = parseInt($('.bar-chart').attr('data-total'));
        if (curBar != i) {
            $('.bar-chart').attr('data-total', i);
        }
        prevPos = curTop;
    });

    $('.my-paroller').paroller();
    var headerHeight = $(".js-header").height();
    $(".js-goto").on("click", function(e){
        e.preventDefault();
        var $this = $(this);
        var id = $this.attr("data-id");
        var top = $(id).offset().top - headerHeight - 100;
        $("body,html").animate({"scrollTop": top + "px"}, 1000);
    });



});
$(document).ready(function () {
    var calcTop = $("#js-calc").offset().top - $(window).height() + 10;

    $(window).scroll(function () {
        var curtop = $(window).scrollTop();
        if (curtop < calcTop) {
            $('.lavnik__five').addClass('calc');
        }
        else
        {
            $('.lavnik__five').removeClass('calc');
        }
    });
});
$(document).ready(function () {
    $('a[href^="#"]').on('click', function (event) {
        // отменяем стандартное действие
        event.preventDefault();

        var sc = $(this).attr("href"),
            dn = $(sc).offset().top;
        /*
         * sc - в переменную заносим информацию о том, к какому блоку надо перейти
         * dn - определяем положение блока на странице
         */

        $('html, body').animate({
            scrollTop: dn
        }, 1000);
        /*
         * 1000 скорость перехода в миллисекундах
         */
    });
});

$('.form1').click(function () {
    $('#popup1').addClass('open');
    $('.modal_close').addClass('open');
    $("html,body").css("overflow","hidden");
    // $('#blur').addClass('blur');
    // $('html').addClass('body__no-scroll');
    // event.stopPropagation();
});

$('.form2').click(function () {
    $('#popup2').addClass('open');
    $('.modal_close').addClass('open');
    $("html,body").css("overflow","hidden");
    // $('#blur').addClass('blur');
    // $('html').addClass('body__no-scroll');
    // event.stopPropagation();
});
$('.form3').click(function () {
    $('#popup3').addClass('open');
    $('.modal_close').addClass('open');
    $("html,body").css("overflow","hidden");
    // $('#blur').addClass('blur');
    // $('html').addClass('body__no-scroll');
    // event.stopPropagation();
});

$('.modal_close').click(function () {
    $('#popup1').removeClass('open');
    $('#popup2').removeClass('open');
    $('#popup3').removeClass('open');
    $("html,body").css("overflow","auto");

});


/*select*/


    var select_1 = document.querySelector('#s_1');

// document.querySelector('.select_1').addEventListener('click', function() {
//
//     select_1.value = 1;
// });
    document.querySelector('.select_2').addEventListener('click', function() {
        select_1.selectedIndex = 1;

    });
// document.querySelector('.select_3').addEventListener('click', function() {
//     select_1.value = 3;
//
// });


    var select_2 = document.querySelector('#s_2');
    document.querySelector('.sel_1').addEventListener('click', function() {

        select_2.selectedIndex = 0;

    });
    document.querySelector('.sel_2').addEventListener('click', function() {
        select_2.selectedIndex = 1;

    });
    document.querySelector('.sel_3').addEventListener('click', function() {
        select_2.selectedIndex = 2;

    });
    document.querySelector('.sel_4').addEventListener('click', function() {
        select_2.selectedIndex = 3;

    });




//яндекс карта

ymaps.ready(function () {
    var myMap = new ymaps.Map('map', {
            center: [55.831498, 37.410604],
            zoom: 16,
            controls: []

        },

        {suppressMapOpenBlock: true}),
        // Создаём макет содержимого.
        MyIconContentLayout = ymaps.templateLayoutFactory.createClass(
            '<div class="map-block" style="color: #FFFFFF; font-weight: bold;">$[properties.iconContent]</div>'
        ),

        myPlacemarkWithContent = new ymaps.Placemark([55.829158, 37.410604], {
            hintContent: 'Лавник и партнеры',
            balloonContent: '',
            iconContent: ''
        }, {
            // Опции.
            // Необходимо указать данный тип макета.
            iconLayout: 'default#imageWithContent',
            // Своё изображение иконки метки.
            iconImageHref: '/_img/lavnik.png',
            // Размеры метки.
            iconImageSize: [170, 150],
            // Смещение левого верхнего угла иконки относительно
            // её "ножки" (точки привязки).
            iconImageOffset: [-65, -135],
            //Смещение слоя с содержимым относительно слоя с картинкой.
            iconContentOffset: [15, 15],
            // Макет содержимого.
            //iconContentLayout: MyIconContentLayout
        });
    myMap.geoObjects
        .add(myPlacemarkWithContent);
});




/*валидация форм*/
$('[name=PHONE]').inputmask({"mask": "+7(999)-999-99-99"});

$("body").on("submit", ".js-form-submit", function (e) {
    e.preventDefault();
    var _form = $(this);
    if (!myValidateForm(_form)) {
        return false;
    }
    var _data = _form.serializeObject();
    if (typeof $order !== 'undefined') {
        _data.order = $order;
    }
    SendAjax("SEND_FORM", _data, function (data) {
        // _form.html(data.html);
        _form.append(data.html);

        /*убираем форму*/
        $("form").addClass( "class_succes" );

        if (data["GOAL"]) {
            sendYandexGoal(data["GOAL"]);
        }
    });



});

$("body").on( "click", ".modal_close ", function (e) {


    e.preventDefault();
    $("form").removeClass( "class_succes" );
    $('form').trigger('reset');
    $(".block_modal").hide();

});

$(document).mouseup(function (e) { // событие клика по веб-документу

    console.log("brn")
    var div = $(".block_input_search, .search_button, .block_bar_city, .navbar-toggler, .popup "); // тут указываем класс элемента
    if (!div.is(e.target) // если клик был не по нашему блоку

        && div.has(e.target).length === 0) { // и не по его дочерним элементам
        $(".navbar-toggleable-xs").removeClass('show');
        $(".block_modal").hide();
        $(".overlay").removeClass('open');
    }
});

function SendAjax(_action, _data, _callBack) {
    _callBack = _callBack || function () {
    };
    $.ajax({
        url: '/ajax.php',
        dataType: 'json',
        type: 'POST',
        data: {
            'action': _action,
            'data': _data,

        },

        error: function (data) {
            console.log(data);
        },
    }).done(function (data) {
        _callBack(data);
        console.log(data);
    });
}

$.fn.serializeObject = function () {
    var self = this,
        json = {},
        push_counters = {},
        patterns = {
            "validate": /^[a-zA-Z][a-zA-Z0-9_]*(?:\[(?:\d*|[a-zA-Z0-9_]+)\])*$/,
            "key": /[a-zA-Z0-9_]+|(?=\[\])/g,
            "push": /^$/,
            "fixed": /^\d+$/,
            "named": /^[a-zA-Z0-9_]+$/
        };
    this.build = function (base, key, value) {
        base[key] = value;
        return base;
    };
    this.push_counter = function (key) {
        if (push_counters[key] === undefined) {
            push_counters[key] = 0;
        }
        return push_counters[key]++;
    };
    $.each($(this).serializeArray(), function () {
        // skip invalid keys
        if (!patterns.validate.test(this.name)) {
            return;
        }
        var k,
            keys = this.name.match(patterns.key),
            merge = this.value,
            reverse_key = this.name;
        while ((k = keys.pop()) !== undefined) {
            // adjust reverse_key
            reverse_key = reverse_key.replace(new RegExp("\\[" + k + "\\]$"), '');
            // push
            if (k.match(patterns.push)) {
                merge = self.build([], self.push_counter(reverse_key), merge);
            }
            // fixed
            else if (k.match(patterns.fixed)) {
                merge = self.build([], k, merge);
            }
            // named
            else if (k.match(patterns.named)) {
                merge = self.build({}, k, merge);
            }
        }
        json = $.extend(true, json, merge);
    });
    return json;
};


function myValidateForm(form) {
    var _items = form.find(".req");
    form.find(".req").removeClass("error");
    var _valid = true;
    form.find('.req:not(.jq-selectbox)').each(function (index, el) { /*проверка заполнения*/
        var _input = $(el);
        $('[name=PHONE]').mask("+7(999)-999-99-99");
        if (_input.val() == "") {
            $(el).addClass('error');
            _valid = false;
        }

        if (_input[0].tagName == "SELECT" && _input.val() == "") {
            $(el).closest(".jq-selectbox").addClass('error');
            _valid = false;
        }
        if (_input.attr("type") == "checkbox" && _input.prop("checked") == false) {
            $(el).addClass('error');
            _valid = false;
        }
        if (_input.attr("name") === "EMAIL" && _input.val() === "") {
        } else if (_input.attr("name") === "EMAIL" && !isValidEmailAddress(_input.val())) {
            $(el).addClass('error');
            _valid = false;
        }
        if (_input.attr("name") === "PASSWORD") {
            var _has_password_error = false;
            if (_input.val() === "") {
            } else if (_input.val().length < 6) {
                _has_password_error = true;
            }
            if (_has_password_error) {
                $(el).addClass('error');
                _valid = false;
            }
        }
        if (_input.attr("name") === "CONFIRM_PASSWORD") {
            var _has_password_confirm_error = false;
            var _password = form.find(".req[name=PASSWORD]");
            if (_input.val() === "") {
            } else if (_input.val() !== _password.val()) {
                _has_password_confirm_error = true;
            }
            if (_has_password_confirm_error) {
                $(el).addClass('error');
                _valid = false;
            }
        }
    });
    return _valid;
}

function isValidEmailAddress(emailAddress) {
    var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
    return pattern.test(emailAddress);
}


function sendYandexGoal(_target) {
    yaCounter49961221.reachGoal(_target);
    ga('send', 'pageview', '/' + _target + '.html');
}






